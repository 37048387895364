@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'PoetsenOne';
    src: url('../public/fonts/PoetsenOne-Regular.ttf') format('truetype');
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html {
    overflow: hidden;
}

@layer components {
    .btn {
        @apply rounded-lg h-fit w-fit text-sm px-2.5 py-1 transition-all;
    }

    .icon-btn {
        @apply rounded-lg text-xl text-center w-8 h-8 transition-all;
    }
}
